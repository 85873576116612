import React from 'react'
import {
    Button,
    Form,
    Input,
    Segment,
    Header,
    Checkbox,
    TextArea,
    Radio,
    Grid,
    Container,
    Icon,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';


function WaitListScreen() {
    return (
        <Container>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                    <Segment color='teal' piled>
                        <Header as='h1'>
                            <Icon name="wordpress forms" />
                            <Header.Content>
                                Waitlist
                                <Header.Subheader>Please fill out the details below to be added on our waitlist</Header.Subheader>
                            </Header.Content>
                        
                        </Header>

                        <Form >
                        <Form.Field
                            control={Input}
                            label="Child's Name"
                            placeholder="Enter Child's Name"
                            name='childsName'
                            
                        />
                        <Form.Field
                            control={Input}
                            label="Child's Date of Birth"
                            placeholder="Enter Childs DOB"
                            name='childsDOB'
                        />

                        <Form.Field>
                            <Radio
                                label='Full Time'
                                name='programOption'
                                value='Full Time'
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='Part Time Mon/Wed/Fri'
                                name='programOption'
                                value='Part Time Mon/Wed/Fri'
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='Part Time Tue/Thurs'
                                name='programOption'
                                value='Part Time Tue/Thurs'
                            />
                        </Form.Field>
                
                        <Form.Field
                            control={Input}
                            label='Parent/Guardian Name'
                            placeholder='Enter Parent/Guardian Name'
                            name='parentGuardianName'
                        />
                        <Form.Field
                            control={Input}
                            label='Paren/tGuardian Phone'
                            placeholder='Enter Parent/Guardian Phone'
                            name='parentGuardianPhone'
                        />
                        <Form.Field
                            control={Checkbox}
                            label='Has your child attend childcare before?'
                            toggle
                            name='childAttendDayCareBefore'
                        />
                        <Form.Field
                            control={Checkbox}
                            label='Does your child have any siblings?'
                            toggle
                            name='childHasSiblings'
                        />
                        <SemanticDatepicker
                            control={Input}
                            label='Requesting Care Start Date'
                            placeholder='Enter the date you wish to start care'
                            name='careStartDate'
                        />
                        <Form.Field
                            control={Input}
                            label='Does your child have allergies?'
                            placeholder='Enter allergies or None'
                            name='childHasAllergies'
                        />
                        <Form.Field>
                            <TextArea 
                                name='additionalComments' 
                                label='Do you have anything else we should be aware of?'
                                placeholder='Enter Additional Information if required'
                            />
                        </Form.Field>
                        <Button color='green' type='submit'>Submit</Button>
                        </Form>
                    </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default WaitListScreen
