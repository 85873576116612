import React from 'react'
import { Container, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import Programs from '../../components/Programs'

function ProgramsScreen() {
    return (
        <Container>
            <Segment piled color='blue'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                        <Header as='h2'>
                            <Icon name='paper plane' />
                            <Header.Content>
                            Programs
                            <Header.Subheader>
                                What do we have to offer? See below!
                            </Header.Subheader>
                            </Header.Content>
                        </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                    
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h3'>Our Programs</Header>
                            <Programs
                                header='Infant - Age 3 months to 18 months'
                                ratio='1 educator to 3 children'
                                details='Description about the toddler program'
                                color='red'
                            />
                            <Programs
                                header='Toddlers - Age 18 months to 3 years'
                                ratio='1 educator to 5 children'
                                details='Description about the toddler program'
                                color='green'
                            />
                            <Programs
                                header='Preschool - Age 4 to 5 years'
                                ratio='1 educator to 10 children'
                                details='Description about the toddler program'
                                color='purple'
                            />

                            <Header as='h3'>Program options:</Header>
                            <p>Full time child is Monday to Friday.</p>
                            <p>Part time child is either Monday/Wednesday/Friday or Tuesday/Thursday</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    )
}

export default ProgramsScreen
