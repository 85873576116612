import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Menu, Sticky, Image } from 'semantic-ui-react'

import bannerLogo from '../bannerlogo.png'
import logo from '../logo.png'

function Header() {
    return (
    <Fragment>
        <div className='header-bg'>
          <Image
            src={bannerLogo}
            className='header-logo'
            centered
            size='big'
          />
        </div>

 
          <Menu
            stackable
            //fixed="top"
            size='small'
            style={{ marginBottom: '2em', marginTop: '-1px' }}
          >
            <div className='header item'>
              <img src={logo} alt='logo'/> &nbsp;
              <Link to='/' className='logo_link'>Creative Beginnings</Link>
            </div>
            <div className='left menu'>
              <Link className='item' to='/'>
                Home
              </Link>
              <Link className='item' to='/team'>
                Our Team
              </Link>
              <Link className='item' to='/programs'>
                Programs
              </Link>
              <Link className='item' to='/resources'>
                Resources
              </Link>
              <Link className='item' to='/waitlist'>
                Waitlist Requests
              </Link>
              <Link className='item' to='/contactus'>
                Contact Us
              </Link>
            </div>
            {/* {isAuthenticated ? authLinks : guestLinks} */}
          </Menu>
      </Fragment>
    )
}

export default Header
