import React from 'react'
import { Carousel } from 'react-responsive-carousel';

function Slider() {
    return (
        <div>
            <Carousel
              infiniteLoop
              autoPlay
              interval='6000'
              showThumbs={false}
              showStatus={false}
            >
              <div>
                <img src="/assets/imgs/slider4.png" />
                <p className="legend">Image 1</p>
              </div>
              <div>
                <img src="/assets/imgs/slider2.png" />
                <p className="legend">Image 2</p>
              </div>
              <div>
                <img src="/assets/imgs/slider3.png" />
                <p className="legend">Image 3</p>
              </div>
            </Carousel>
        </div>
    )
}

export default Slider
