import React from 'react'
import { Card as SemanticCard, Image } from 'semantic-ui-react'

function Card({image, header, meta, description, color}) {
    return (
        <SemanticCard centered color={color}>
            <Image src={image} wrapped ui={false} />
            <SemanticCard.Content>
                <SemanticCard.Header>{header}</SemanticCard.Header>
                <SemanticCard.Meta>
                    {meta}
                </SemanticCard.Meta>
                <SemanticCard.Description>
                    {description}
                </SemanticCard.Description>
            </SemanticCard.Content>
        </SemanticCard>
    )
}

export default Card
