import React from 'react'
import {
    Container,
    Image,
    List,
    Segment,
  } from 'semantic-ui-react'

  import Logo from '../logo.png'

function Footer() {
    const d = new Date()
    const year = d.getFullYear()
    return (
        <Segment color="violet" inverted vertical style={{position: 'relative', marginTop: 30, left: 0, bottom: 0}}>
            <Container textAlign='center'>
                <Image centered size='mini' src={Logo} />
                <List horizontal inverted divided link size='small'>
                    <List.Item as='a' href='#'>
                        Site Map
                    </List.Item>
                    <List.Item as='a' href='#'>
                        Contact Us
                    </List.Item>
                    <List.Item as='a' href='#'>
                        Terms and Conditions
                    </List.Item>
                    <List.Item as='a' href='#'>
                        Privacy Policy
                    </List.Item>
                </List>
                <p>
                    Copyright &copy; {year} Creative Beginnings Early Learning Centre. All Rights Reserved
                </p>
            </Container>
        </Segment>
    )
}

export default Footer
