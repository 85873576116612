import React from 'react'
import { Grid, Header, Icon, Container, Segment } from 'semantic-ui-react';
import Card from '../../components/Card'


function OurTeamScreen() {
    return (
        <div>
            <Container>
                <Segment piled color='orange'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                            <Header as='h2'>
                                <Icon name='users' />
                                <Header.Content>
                                Meet Our Team!
                                <Header.Subheader>
                                    Here is our amazing team
                                </Header.Subheader>
                                </Header.Content>
                            </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    
                    <Grid columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <Card 
                                    image='/assets/imgs/photo1.png'
                                    header='Sally Jones'
                                    meta='Early Childhood Educator'
                                    description='Sally has been doing this a long time. She is so frigging awesome, how could you not like her!'
                                    color='brown'
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Card 
                                    image='/assets/imgs/photo2.png'  
                                    header='Sally Jones'
                                    meta='Early Childhood Educator'
                                    description='Sally has been doing this a long time. She is so frigging awesome, how could you not like her!'
                                    color='black'
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Card 
                                    image='/assets/imgs/photo3.png' 
                                    header='Sally Jones'
                                    meta='Early Childhood Educator'
                                    description='Sally has been doing this a long time. She is so frigging awesome, how could you not like her!'
                                    color='pink'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        </div>
    )
}

export default OurTeamScreen
