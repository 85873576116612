import React from 'react'
import { 
    Container, 
    Segment, 
    Grid, 
    Header, 
    Icon,
    Form,
    Input,
    Select,
    TextArea,
    Button
} from 'semantic-ui-react'

function ContactUsScreen() {
    const contactOptions = [
        { key: 'c', text: 'Phone', value: 'phone' },
        { key: 'e', text: 'Email', value: 'email' },
      ]
    return (
        <Container>
            <Segment piled color='black'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                        <Header as='h2'>
                            <Icon name='mail' />
                            <Header.Content>
                            Contact Us
                            <Header.Subheader>
                                Fill out the form below and we will get back to you as soon as we can!
                            </Header.Subheader>
                            </Header.Content>
                        </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        id='form-input-control-first-name'
                                        control={Input}
                                        label='First name'
                                        placeholder='First name'
                                        required
                                    />
                                    <Form.Field
                                        id='form-input-control-last-name'
                                        control={Input}
                                        label='Last name'
                                        placeholder='Last name'
                                        required
                                    />
                                    <Form.Field
                                        id='form-input-control-childs-name'
                                        control={Input}
                                        label="Child's name (Not Required)"
                                        placeholder="Child's name"
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        id='form-input-control-email'
                                        control={Input}
                                        label='Email'
                                        placeholder='example@email.com'
                                        required
                                    />
                                    <Form.Field
                                        id='form-input-control-phone'
                                        control={Input}
                                        label='Phone'
                                        placeholder='902-396-1234'
                                        required
                                    />
                                    <Form.Field
                                        control={Select}
                                        options={contactOptions}
                                        label={{ children: 'Contact Preference', htmlFor: 'form-select-control-contact' }}
                                        placeholder='Contact Preference'
                                        search
                                        searchInput={{ id: 'form-select-control-contact' }}
                                        required
                                    />
                                </Form.Group>
                                <Form.Field
                                    id='form-textarea-control-comments'
                                    control={TextArea}
                                    label='Comments'
                                    placeholder='Comments'
                                    required
                                />
                                <Form.Field
                                    id='form-button-control-public'
                                    control={Button}
                                    content='Submit'
                                />
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    )
}

export default ContactUsScreen
