import React, { Fragment } from 'react'
import Loader from '../../components/Loader'
import Slider from '../../components/Slider'
import { Grid, Segment, Header, Icon, Container } from 'semantic-ui-react';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

import News from '../../components/News'

function HomeScreen() {
    return (
        <Fragment>
            <Grid>
                <Grid.Row style={{padding: '0 14px 14px 14px', marginTop: '-12px'}}>
                    <Slider />
                </Grid.Row>
            </Grid>
            <Container style={{marginTop: 20}}>
                <Segment piled color='pink'>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                            <Header as='h2'>
                                <Icon name='home' />
                                <Header.Content>
                                Welcome to Creative Beginnings
                                <Header.Subheader>
                                    Early Learning Center
                                </Header.Subheader>
                                </Header.Content>
                            </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris dignissim dolor vitae metus feugiat, in maximus magna porta. Nunc ac eleifend ligula, nec blandit elit. Nam ipsum quam, 
                                fringilla et vulputate ac, vehicula in neque. Suspendisse molestie, augue in laoreet tristique, massa elit mattis velit, in condimentum sem urna blandit velit. Aenean est leo, semper non metus et, 
                                facilisis porttitor dui. Aliquam facilisis gravida leo in volutpat. Proin lobortis facilisis nunc in lacinia. Nunc nec posuere purus, et eleifend augue. Ut sem dolor, maximus eu tincidunt in, </p>
                            <p>lobortis et urna. In hac habitasse platea dictumst. Etiam maximus sem iaculis vulputate lobortis. Integer sollicitudin quam elit, elementum placerat felis hendrerit non. Integer aliquet mattis odio, 
                                id tincidunt sapien aliquet in. Maecenas sit amet libero imperdiet, facilisis lacus sit amet, interdum nibh. Donec pretium viverra molestie. Sed fermentum nisl arcu, vel aliquet arcu facilisis quis. 
                                Proin sed eros dolor. Suspendisse facilisis id dui id tempus. Proin vel dui ac felis consectetur laoreet ut vel mauris. Etiam ante quam, fermentum nec tellus id, aliquet pharetra mauris. Donec arcu orci, 
                                vestibulum vitae turpis eu, lacinia bibendum lacus. Nunc ornare, sem vitae venenatis facilisis, erat sem bibendum velit, eget iaculis eros libero eu mi. Sed sagittis enim vitae tempor lacinia. Suspendisse 
                                bibendum laoreet vehicula. Nunc egestas ullamcorper nisi, in molestie nisl vestibulum vitae. Pellentesque blandit purus id justo convallis, in auctor risus feugiat. Duis sit amet nulla eget turpis egestas 
                                efficitur sed vel est. Etiam imperdiet facilisis rhoncus. Donec ornare eleifend odio, non pulvinar augue euismod nec. Ut semper sagittis bibendum. Donec vehicula nisi ac libero venenatis, vel aliquet lectus 
                                auctor. Proin nisi risus, eleifend vel egestas non, aliquam in quam. Cras sed est quis libero vehicula fermentum vitae quis justo. Nullam fermentum volutpat ipsum, ut egestas urna porta ut. Sed blandit, 
                                lorem nec dignissim dignissim, ligula sem aliquet nisi, nec porttitor tellus neque non diam. Nunc at rhoncus ante, nec bibendum est. Integer commodo nulla sit amet tortor venenatis gravida. Suspendisse ac 
                                aliquam purus. Ut bibendum pharetra tellus sit amet euismod. Sed quam velit, tempor congue velit sit amet, commodo finibus ante. Praesent sollicitudin, quam ut blandit bibendum, ipsum ligula viverra justo, 
                                nec aliquet lorem enim in purus. Quisque fermentum mauris id bibendum gravida. Sed sed finibus odio. Etiam sodales dignissim nunc, ac euismod nisl cursus quis. In hac habitasse platea dictumst. Fusce at 
                                pulvinar mi. Sed sapien augue, faucibus ac orci ut, elementum finibus tellus. Pellentesque gravida, nisi non sollicitudin sagittis, magna neque faucibus mauris, et dictum dui ante porttitor nibh. Nulla 
                                facilisi. Donec pellentesque tortor tellus, eu laoreet purus luctus vitae. Nunc vestibulum hendrerit congue. Vivamus in porta magna. Fusce vulputate maximus nisi, tempus posuere</p>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column >
                                <News 
                                    title='Super Cool News Article' 
                                    date='Sep 23, 2021'
                                    details='lobortis et urna. In hac habitasse platea dictumst. Etiam maximus sem iaculis vulputate lobortis. Integer sollicitudin quam elit, elementum placerat felis hendrerit non. Integer aliquet mattis odio, 
                                    id tincidunt sapien aliquet in. Maecenas sit amet libero imperdiet, facilisis lacus sit amet, interdum nibh. Donec pretium viverra molestie. Sed fermentum nisl arcu, vel aliquet arcu facilisis quis. 
                                    Proin sed eros dolor. Suspendisse facilisis id dui id tempus. Proin vel dui ac felis consectetur laoreet ut vel mauris. Etiam ante quam, fermentum nec tellus id, aliquet pharetra mauris. Donec arcu orci, 
                                    vestibulum vitae turpis eu, lacinia bibendum lacus. Nunc ornare, sem vitae venenatis facilisis, erat sem bibendum velit, eget iaculis eros libero eu mi. Sed sagittis enim vitae tempor lacinia. Suspendisse 
                                    bibendum laoreet vehicula. Nunc egestas ullamcorper nisi, in molestie nisl vestibulum vitae. Pellentesque blandit purus id justo convallis, in auctor risus feugiat. Duis sit amet nulla eget turpis egestas 
                                    efficitur sed vel est. Etiam imperdiet facilisis rhoncus. Donec ornare eleifend odio, non pulvinar augue euismod nec. Ut semper sagittis bibendum. Donec vehicula nisi ac libero venenatis, vel aliquet lectus 
                                    auctor. Proin nisi risus, eleifend vel egestas non, aliquam in quam. Cras sed est quis libero vehicula fermentum vitae quis justo. Nullam fermentum volutpat ipsum, ut egestas urna porta ut. Sed blandit, 
                                    lorem nec dignissim dignissim, ligula sem aliquet nisi, nec porttitor tellus neque non diam. Nunc at rhoncus ante, nec bibendum est. Integer commodo nulla sit amet tortor venenatis gravida. Suspendisse ac 
                                    aliquam purus. Ut bibendum pharetra tellus sit amet euismod. Sed quam velit, tempor congue velit sit amet, commodo finibus ante. Praesent sollicitudin, quam ut blandit bibendum, ipsum ligula viverra justo, 
                                    nec aliquet lorem enim in purus. Quisque fermentum mauris id bibendum gravida. Sed sed finibus odio. Etiam sodales dignissim nunc, ac euismod nisl cursus quis. In hac habitasse platea dictumst. Fusce at 
                                    pulvinar mi. Sed sapien augue, faucibus ac orci ut, elementum finibus tellus. Pellentesque gravida, nisi non sollicitudin sagittis, magna neque faucibus mauris, et dictum dui ante porttitor nibh. Nulla 
                                    facilisi. Donec pellentesque tortor tellus, eu laoreet purus luctus vitae. Nunc vestibulum hendrerit congue. Vivamus in porta magna. Fusce vulputate maximus nisi, tempus posuere'
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Container>
        </Fragment>
    )
}

export default HomeScreen
