import React from 'react'
import { Dimmer, Loader as DisplayLoader } from 'semantic-ui-react'

function Loader() {
    return (
        <Dimmer active inverted>
            <DisplayLoader>Loading...</DisplayLoader>
        </Dimmer>
    )
}

export default Loader
