import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'

import HomeScreen from './screens/main/HomeScreen'
import OurTeamScreen from './screens/main/OurTeamScreen';
import ProgramsScreen from './screens/main/ProgramsScreen';
import ResourcesScreen from './screens/main/ResourcesScreen';
import WaitListScreen from './screens/main/WaitListScreen';
import ContactUsScreen from './screens/main/ContactUsScreen';


function App() {
  return (
    <Router>
      <Header />
      <main style={{minHeight: 'calc(100vh - 369px)'}}>
        <Route path='/' component={HomeScreen} exact />
        <Route path='/team' component={OurTeamScreen} />
        <Route path='/programs' component={ProgramsScreen} />
        <Route path='/resources' component={ResourcesScreen} />
        <Route path='/waitlist' component={WaitListScreen} />
        <Route path='/contactus' component={ContactUsScreen} />
      </main>
      <Footer />
    </Router>
  );
}

export default App;
