import React from 'react'
import { Container, Segment, Grid, Header, Icon, List } from 'semantic-ui-react'

function ResourcesScreen() {
    return (
        <Container>
            <Segment piled color='yellow'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                        <Header as='h2'>
                            <Icon name='desktop' />
                            <Header.Content>
                            Resources
                            <Header.Subheader>
                                Please feel free to use any of the the resources you see below.
                            </Header.Subheader>
                            </Header.Content>
                        </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <List>
                                <List.Item>
                                    <List.Icon name='linkify' />
                                    <List.Content>
                                        <List.Header as='a'>Parent Document A</List.Header>
                                        <List.Description>
                                            Description about Parent Document A
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='linkify' />
                                    <List.Content>
                                        <List.Header as='a'>Parent Document B</List.Header>
                                        <List.Description>
                                            Description about Parent Document B
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='linkify' />
                                    <List.Content>
                                        <List.Header as='a'>Parent Document C</List.Header>
                                        <List.Description>
                                            Description about Parent Document C
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='linkify' />
                                    <List.Content>
                                        <List.Header as='a'>Parent Document D</List.Header>
                                        <List.Description>
                                            Description about Parent Document D
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    )
}

export default ResourcesScreen