import React, { Fragment } from 'react'
import { Grid, Header, Icon, Image, Container } from 'semantic-ui-react';

function News({title, date, details}) {
    return (
        <Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                    <Header as='h2'>
                        <Icon name='newspaper' />
                        <Header.Content>
                            {title}
                            <Header.Subheader>
                                {date}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                    <p>{details}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default News
