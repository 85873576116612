import React from 'react'
import { Segment, Item } from 'semantic-ui-react'

function Programs({header, ratio, details, color}) {
    return (
        <Segment
            raised
            piled
            color={color}
        >
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Item.Header>{header}</Item.Header>
                        <Item.Meta>Ration: {ratio}</Item.Meta>
                        <Item.Description>{details}</Item.Description>
                    </Item.Content>
                </Item>
            </Item.Group>
        </Segment>
    )
}

export default Programs
